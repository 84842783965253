<template>
  <v-row>
    <v-col>
      <v-card md="12" cols="12" v-if="getFirmantes?.length>0">
        <v-card-title>
          <h3>Listado de firmantes oferentes</h3>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item dense v-for="(item, index) in getFirmantes" :key="index">
              <v-list-item-title>
                <span class="text-decoration-underline text-decoration-overline text-decoration-overline"
                      v-text="item.nombre"></span>
              </v-list-item-title>
              <v-list-item-action v-if="actions">
                <v-row>
                  <v-col md="6" cols="6">
                    <v-btn icon @click="modificarFirmante(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="6" cols="6">
                    <v-btn icon color="red" @click="removerFirmante(item.id_memoria)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <br>
      <v-card  md="12" cols="12" v-if="getFirmantesDinac?.length>0">
        <v-card-title>
          <h3>Listado de firmantes DINAC</h3>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item dense v-for="(item, index) in getFirmantesDinac" :key="index">
              <v-list-item-title>
                <span class="text-decoration-underline text-decoration-overline text-decoration-overline"
                      v-text="item.nombre"></span>
              </v-list-item-title>
              <v-list-item-action v-if="actions">
                <v-row>
                  <v-col md="6" cols="6">
                    <v-btn icon @click="modificarFirmanteDinac(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col md="6" cols="6">
                    <v-btn icon color="red" @click="removerFirmanteDinac(item.id_memoria)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
export default {
  name: "CMConvenioInstitucionFirmantesListado",
  computed: {
    ...mapGetters("cmConvenioStore", ["getFirmantes", "getFirmanteEdit", "getFirmantesDinac", "getFirmanteDinacEdit"]),
  },
  methods: {
    ...mapActions("cmConvenioStore", ["removerFirmante", "editarFirmante", "removerFirmanteDinac", "editarFirmanteDinac"]),
    modificarFirmante(item) {
      if (!this.getFirmanteEdit) {
        this.editarFirmante(item);
      } else {
        this.temporalAlert({
          show: true,
          message: 'Tiene un registro pendiente de modificar',
          type: "warning",
        });
      }
    },
    modificarFirmanteDinac(item) {
      if (!this.getFirmanteDinacEdit) {
        this.editarFirmanteDinac(item);
      } else {
        this.temporalAlert({
          show: true,
          message: 'Tiene un registro pendiente de modificar',
          type: "warning",
        });
      }
    },
  },
  props: {
    actions: {
      type: Boolean,
      default: true
    }
  },
};
</script>

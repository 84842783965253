<template>
  <v-row>
    <v-col md="12" cols="12">
      <v-row>
        <v-col md="6" cols="12">
          <h3>Institución</h3>
          <label>Nombre: </label><span v-text="institucion.nombre"></span>
          <br>
          <label> Nombre comercial: </label><span v-text="institucion.nombre_comercial"></span>
          <br>
          <label> Teléfono de la institución: </label><span v-text="telefono"></span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import {mapState,} from "vuex";

export default {
  name: "CMConvenioInicioRead",
  computed: {
    ...mapState("cmConvenioStore", ["institucion", "telefono"]),
  },
};
</script>

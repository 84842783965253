<template>
  <v-row>
    <v-col md="12" cols="12">
      <v-row>
        <v-col md="6" cols="12">
          <h3>Fecha de convenio</h3>
          <span v-text="moment(fecha_convenio).format('DD-MM-YY')"></span>
        </v-col>
        <v-col md="6" cols="12">
          <h3>Tipo de convenio</h3>
          <span v-text="tipo_convenio?.nombre??''"></span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import {mapState,} from "vuex";

export default {
  name: "CMConvenioInicioRead",
  computed: {
    ...mapState("cmConvenioStore", ["fecha_convenio", "tipo_convenio"]),
  },
};
</script>

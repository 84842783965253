<template>
  <div class="upsert-product-modal pb-8 pt-16 px-8 position-relative"
    style="position: relative; padding-top: 10px !important;">
    <v-row>
      <v-col md="12" cols="12">
        <h1>Direcciones</h1>
        <v-list>
          <v-list-item dense v-for="(item, index) in getDirecciones" :key="index">
            <v-list-item-title>
              <span class="underlined" v-text="item.direccion"></span>
            </v-list-item-title>
            <v-list-item-action v-if="actions">
              <v-row>
                <v-col md="6" cols="6">
                  <v-btn icon @click="modificarDireccion(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col md="6" cols="6">
                  <v-btn icon color="red" @click="manejarEliminado(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <div class="text-center mt-4">
      <v-btn class="secondary" @click.stop="cerrarModal">Cerrar</v-btn>
    </div>
    <ConfirmationModalComponent
          :isOpen="modalConfirmacionEliminar" 
          description="¿Desea continuar con la acción?"
          @confirm="confirmarEliminar"
          @cancel="modalConfirmacionEliminar = false"
          :isLoading="upsertAction.isLoading" 
      />
  </div>
</template>

<script>
import { ConfirmationModalComponent } from "@/components/utils";
import { mapGetters, mapActions } from "vuex";

import { 
    createLoadable, 
    toggleLoadable,
    setLoadableResponse,
} from '@/utils/loadable.js';

export default {
  name: "CMConvenioInstitucionDireccionesListado",
  computed: {
    ...mapGetters("cmConvenioStore", ["getDirecciones", "getDireccionEdit"]),
  },
  props: {
    actions: { type: Boolean, default: true },
    oferta: { type: Object },
  },
  components: { ConfirmationModalComponent },

  data: () => ({
    modalConfirmacionEliminar: null,
    idOferta: null,
    direccionaActiva: null,
    upsertAction: createLoadable(null),
  }),
  methods: {
    ...mapActions("cmConvenioStore", ["agregarOfertaDireccion", "agregarOferta", "eliminarDireccion", "editarDireccion"]),
    
    async confirmarEliminar() {
      toggleLoadable(this.upsertAction);
      const  data  = await this.eliminarDireccion({ direccion: this.direccionaActiva, idOferta: this.oferta.id });
      setLoadableResponse(this.upsertAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalConfirmacionEliminar = false;
    },

    manejarEliminado(item) {
      this.direccionaActiva = item;
      this.modalConfirmacionEliminar = true;
    },
    modificarDireccion(item) {
      if (!this.getDireccionEdit) {
        this.editarDireccion(item);
      }
      else {
        this.temporalAlert({
          show: true,
          message: 'Tiene un registro pendiente de modificar',
          type: "warning",
        });
      }
    },
    cerrarModal() {
      this.$emit('close');
    },
  },
};
</script>

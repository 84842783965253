<template>
  <v-row>
    <v-col md="12" cols="12">
      <h1>Ofertas añadidas</h1>
      <DataTableComponent :tiene_paginacion="false" :headers="headers" :items="getListadoOfertas" ref="dataTable">
        <template v-slot:[`item.foto`]="{ item }">
          <v-img
              max-height="45"
              max-width="45"
              :src="item.CMProducto.foto"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="modificarOferta(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="removerOferta(item.id_memoria)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.atributos`]="{ item }">
          <v-chip
              v-for="(atributo, index) in item.ofeProCon_atrOfe"
              :key="index"
              color="white"
              label
          >
            {{ atributo?.CMAtributoSubgrupo?.CMAtributo?.nombre }}:
            {{ atributo?.valor }}
            {{ atributo?.CMAtributoSubgrupo?.CMAtributo?.nombre ?? 'Sin unidad' }}
          </v-chip>
        </template>
      </DataTableComponent>
    </v-col>
  </v-row>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CMConvenioOfertaListado",
  components: {DataTableComponent},
  data: () => ({
    headers: [
      {
        text: 'Imagen',
        value: 'foto',
        align: 'center'
      },
      {
        text: 'Nombre',
        value: 'CMProducto.nombre',
        align: 'center'
      },
      {
        text: 'Precio',
        value: 'precio',
        align: 'center'
      },
      {
        text: 'Cantidad',
        value: 'cantidad_ingreso',
        align: 'center'
      }
    ],
  }),
  props: {
    actions: {
      type: Boolean,
      default: true
    },
    detalle: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    getListadoOfertas: {
      handler() {
        this.$refs.dataTable?.setItemPerPage(this.getListadoOfertas.length);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("cmConvenioStore", ["getListadoOfertas", "getOfertaEdit"]),
    listado() {
      return this.getConvenio.regiones
    }
  },
  methods: {
    ...mapActions("cmConvenioStore", ["removerOferta", "editarOferta"]),
    modificarOferta(item) {
      if (!this.getOfertaEdit) {
        this.editarOferta(item);
      } else {
        this.temporalAlert({
          show: true,
          message: 'Tiene un registro pendiente de modificar',
          type: "warning",
        });
      }
    },
  },
  created() {
    if (this.detalle) this.headers.push({
      text: 'Atributos configurados',
      value: 'atributos',
      sortable: false,
      align: 'start'
    })
    if (this.actions) this.headers.push({text: 'Acciones', value: 'actions', sortable: false})
  }
};
</script>

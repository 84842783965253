<template>
<div class="upsert-product-modal pb-8 pt-16 px-8 position-relative" style="position: relative; padding-top: 10px !important;">
  <v-row>
    <v-col md="12" cols="12">
      <h1>Regiones</h1>
      <DataTableComponent :tiene_paginacion="false" :headers="headers" :items="regionesConHoras">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="modificarRegion(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="manejarEliminado(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </DataTableComponent>
    </v-col>
  </v-row>
  <div class="text-center mt-4">
      <v-btn class="secondary" @click.stop="cerrarModal">Cerrar</v-btn>
    </div>
  <ConfirmationModalComponent
          :isOpen="modalConfirmacionEliminar" 
          description="¿Desea continuar con la acción?"
          @confirm="confirmarEliminar"
          @cancel="modalConfirmacionEliminar = false"
          :isLoading="upsertAction.isLoading" 
      />
</div>
</template>
<script>
import { ConfirmationModalComponent } from "@/components/utils";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import {mapGetters, mapActions} from "vuex";
import { 
    createLoadable, 
    toggleLoadable,
    setLoadableResponse,
} from '@/utils/loadable.js';

export default {
  name: "CMConvenioOfertaRegion",
  components: { DataTableComponent, ConfirmationModalComponent },
  data: () => ({
    headers: [
      {
        text: 'Región',
        value: 'CMRegion.nombre',
        align: 'center'
      },
      {
        text: 'Tiempo de respuesta',
        value: 'tiempo_respuesta',
        align: 'center'
      },
      {
        text: 'Tiempo de respuesta en emergencia',
        value: 'tiempo_respuesta_eme',
        align: 'center'
      }
    ],
    modalConfirmacionEliminar: false,
    upsertAction: createLoadable(null),


  }),
  props: {
    actions: {
      type: Boolean,
      default: true
    },
    oferta: { type: Object },

  },
  computed: {
    ...mapGetters("cmConvenioStore", ["getListadoRegiones", "getRegionEdit"]),
    regionesConHoras() {
      return this.getListadoRegiones.map(region => ({
        ...region,
      tiempo_respuesta: `${region.tiempo_respuesta} horas`,
      tiempo_respuesta_eme: `${region.tiempo_respuesta_eme} horas`,
      }));
    },

  },
  methods: {
    ...mapActions("cmConvenioStore", ["eliminarRegion", "editarRegion"]),
    async confirmarEliminar() {
      toggleLoadable(this.upsertAction);
      const  data  = await this.eliminarRegion({ region: this.regionActiva, idOferta: this.oferta.id });
      setLoadableResponse(this.upsertAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalConfirmacionEliminar = false;
    },

    manejarEliminado(item) {
      this.regionActiva = item;
      this.modalConfirmacionEliminar = true;
    },
    
    modificarRegion(item) {
      if (!this.getRegionEdit) {
        const editedItem = { ...item };
        if (editedItem.tiempo_respuesta && editedItem.tiempo_respuesta.endsWith("horas")) {
          editedItem.tiempo_respuesta = editedItem.tiempo_respuesta.slice(0, -6);
        }
        if (editedItem.tiempo_respuesta_eme && editedItem.tiempo_respuesta_eme.endsWith("horas")) {
          editedItem.tiempo_respuesta_eme = editedItem.tiempo_respuesta_eme.slice(0, -6); ;
        }
        this.editarRegion(editedItem);
      } else {
        this.temporalAlert({
          show: true,
          message: 'Tiene un registro pendiente de modificar',
          type: "warning",
        });
      }
    },
    cerrarModal() {
      this.$emit('close');
    },

  },
  created() {
    if (this.actions) this.headers.push({text: 'Acciones', value: 'actions', sortable: false})
  }
}
;
</script>
